**,
*::before,
*::after {
    box-sizing: border-box;
    outline: none;
}
.map {
  height: 100%;
}
/* Optional: Makes the sample page fill the window. */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}
